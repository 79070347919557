import React from "react";
import { AuthConsumer } from "../../helpers/authProvider";
import Spinner from "../icons/Spinner";

const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Spinner
            className="box-content spinner"
            height="40px"
            width="40px"
            fill="#C9CACC"
          />
        </div>
      );
    }}
  </AuthConsumer>
);

export default Logout;

